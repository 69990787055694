@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // borderをレスポンシブで調整可能に
    .border#{$infix}-0 {
      border-width: 0px !important;
    }
    .border#{$infix}-1 {
      border-width: 1px !important;
    }
  }
}
