@import './common';

.mathdown-colorful {
  @include common-modules;

  #{H1},
  #{H2} {
    position: relative;
    padding-bottom: 0.25rem;
    border-bottom: 0.25rem solid #ddd;
    &::after {
      position: absolute;
      content: ' ';
      border-bottom: solid 0.25rem #446689;
      bottom: -0.25rem;
      width: 10%;
      display: block;
    }
  }

  #{H3} {
    border-left: solid 0.4rem #446689;
    padding: 0.2rem 0.5rem;
    font-weight: 400;
  }

  #{H4} {
    &:before {
      content: '✔';
      font-weight: 900;
      color: #5ab9ff;
    }
  }

  blockquote {
    padding: 0.5rem 0 0.5rem 0.5rem;
    background: #f3f3f3;
    border-left: solid 0.4rem #cccccc;
    blockquote {
      margin: 0.5rem 0 0.5rem 0.25rem;
    }
  }

  /**
  * ボックス
  */
  // 予想
  div.conj {
    border: solid 3px #343a40;
    .title {
      background: #343a40;
      color: white;
      padding: 0.5rem 0.75rem;
    }
    .body {
      padding: 0.75rem;
    }
  }

  // 公理
  div.axm {
    border: solid 3px #637587;
    .title {
      background: #637587;
      color: white;
      padding: 0.5rem 0.75rem;
    }
    .body {
      padding: 0.75rem;
    }
  }

  // 定義
  div.def {
    border: solid 3px #11508e;
    .title {
      background: #11508e;
      color: white;
      padding: 0.5rem 0.75rem;
    }
    .body {
      padding: 0.75rem;
    }
  }

  // 命題, 補題, 定理, 系
  div.prop,
  div.lem,
  div.thm,
  div.cor {
    border: solid 3px #17b0c4;
    .title {
      background: #17b0c4;
      color: white;
      padding: 0.5rem 0.75rem;
    }
    .body {
      padding: 0.75rem;
    }
  }

  // 公式
  div.fml {
    border: solid 2px #1e90ff;
    .title {
      color: #1e90ff;
    }
  }

  // 証明
  div.prf {
    position: relative;
    border: none;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 1em;
      border-left: dotted 2px #1a2259;
      border-top: dotted 2px #1a2259;
      border-bottom: dotted 2px #1a2259;
    }
  }

  // 例
  div.ex {
    border: solid 2px #d4bd77;
    .title {
      color: #d4bd77;
    }
  }

  // 問題
  // div.exc {
  // }

  // 注意
  div.rem {
    border: none;
    background-color: #ffebee;
    .title {
      color: #ef5350;
    }
  }

  strong {
    color: red;
  }
}
