.prosemirror-editor-container {
  width: 100%;
  height: 100%;
  .ProseMirror {
    width: 100%;
    height: 100%;
    white-space: pre-wrap;
    font: 18px / normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas',
      'Source Code Pro', 'source-code-pro', monospace, sans-serif;
    line-height: 1.6;
    overflow-y: auto;
    p {
      margin-bottom: 0;
    }
    &:focus-visible {
      outline: 0;
    }
    .search-highlight {
      background-color: rgb(252, 225, 192);
    }

    .search-highlight.selected {
      background-color: rgb(247, 194, 130);
    }

    .selected-line {
      background-color: rgb(232, 232, 232);
    }
    .selected-bracket,
    .matching-bracket {
      background-color: rgb(192, 252, 193);
    }
    .placeholder-text {
      color: rgba(138, 138, 138, 0.75) !important;
    }
  }
}

// 共同編集の場合のカーソルのデザインなど
.prosemirror-editor-container.shared {
  /* this is a rough fix for the first cursor position when the first paragraph is empty */
  .ProseMirror > .ProseMirror-yjs-cursor:first-child {
    margin-top: 16px;
  }

  .ProseMirror p:first-child,
  .ProseMirror h1:first-child,
  .ProseMirror h2:first-child,
  .ProseMirror h3:first-child,
  .ProseMirror h4:first-child,
  .ProseMirror h5:first-child,
  .ProseMirror h6:first-child {
    margin-top: 16px;
  }

  /* This gives the remote user caret. The colors are automatically overwritten*/
  .ProseMirror-yjs-cursor {
    position: relative;
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-color: orange;
    word-break: normal;
    pointer-events: none;
  }

  /* This renders the username above the caret */
  .ProseMirror-yjs-cursor > div {
    position: absolute;
    top: -1.05em;
    left: -1px;
    font-size: 13px;
    background-color: rgb(250, 129, 0);
    font-family: serif;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    user-select: none;
    color: white;
    padding-left: 2px;
    padding-right: 2px;
    white-space: nowrap;
  }
}
