@import 'highlight.js/styles/agate.css';

/**
 * 変数
 */
$H1: 'h1, .h1';
$H2: 'h2, .h2';
$H3: 'h3, .h3';
$H4: 'h4, .h4';
$H5: 'h5, .h5';
$H6: 'h6, .h6';
$MIN_XS: 'min-width: 320px';
$MIN_SM: 'min-width: 576px';
$MIN_MD: 'min-width: 768px';
$MIN_LG: 'min-width: 992px';
$MIN_XL: 'min-width: 1200px';

// $property : プロパティ名
// $min-size : スクリーンサイズが$min-screen-widthの時の$propertyの大きさ(px)
// $max-size : スクリーンサイズが$max-screen-widthの時の$propertyの大きさ(px)
// $min-screen-width以下は$min-size固定→徐々に変化→$max-screen-width以上は$max-sizeに固定になるmixin
@mixin responsible-size(
  $property,
  $min-size,
  $max-size,
  $min-screen-width,
  $max-screen-width
) {
  @media (max-width: #{$min-screen-width}px) {
    #{$property}: #{$min-size}px;
  }
  #{$property}: calc(
    #{$min-size}px + (1vw - #{$min-screen-width}px / 100) * 100 *
      (#{$max-size} - #{$min-size}) /
      (#{$max-screen-width} - #{$min-screen-width})
  );
  @media (min-width: #{$max-screen-width}px) {
    #{$property}: #{$max-size}px;
  }
}

// depthを深くしすぎると本番環境のbuild後のCSSで動かなくなるので注意
// 10以上は動かない。4は動いた
@mixin clear-initial-margin-top($depth) {
  @if ($depth > 1) {
    > :first-child:not(.d-none) {
      margin-top: 0 !important;
      @include clear-initial-margin-top($depth - 1);
    }
    > :first-child.d-none + * {
      margin-top: 0 !important;
      @include clear-initial-margin-top($depth - 1);
    }
  } @else {
    > :first-child:not(.d-none) {
      margin-top: 0 !important;
    }
    > :first-child.d-none + * {
      margin-top: 0 !important;
    }
  }
}

// depthを深くしすぎると本番環境のbuild後のCSSで動かなくなるので注意
// 10以上は動かない。4は動いた
@mixin clear-last-margin-bottom($depth) {
  @if ($depth > 1) {
    > :last-child {
      margin-bottom: 0 !important;
      @include clear-last-margin-bottom($depth - 1);
    }
  } @else {
    > :last-child {
      margin-bottom: 0 !important;
    }
  }
}

@mixin common-modules {
  overflow: hidden;
  counter-reset: def thm thm-prop-lem cor fml ex exc image table;

  /**
  * 共通
  */
  p,
  blockquote,
  ol,
  ul,
  table {
    letter-spacing: 0.075em;
    line-height: 1.85;
    font-size: 1rem;
  }

  /**
  * 見出し
  */
  #{$H1},
  #{$H2},
  #{$H3},
  #{$H4},
  #{$H5},
  #{$H6} {
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.075em;
    font-feature-settings: 'palt';
  }

  #{H1},
  #{H2} {
    @include responsible-size(font-size, 18, 25, 320, 576);
    margin-top: 3rem;
  }
  #{H3} {
    @include responsible-size(font-size, 18, 23, 320, 576);
    margin-top: 2rem;
  }
  #{H4} {
    @include responsible-size(font-size, 18, 20, 320, 576);
    &::before {
      content: '';
      margin-right: 0.2em;
      background-color: #5ab9ff;
      display: inline-block;
      height: 1em;
      width: 1em;
      mask: url('./icons/check-solid.svg');
      -webkit-mask: url('./icons/check-solid.svg');
      mask-size: cover;
      -webkit-mask-size: cover;
      vertical-align: middle;
    }
    margin-top: 1.5rem;
  }
  #{H5},
  #{H6} {
    @include responsible-size(font-size, 16, 17, 320, 576);
    margin-top: 1rem;
  }

  /**
  * 段落
  */
  p {
    margin-top: 1rem;
  }

  /**
  * 引用
  */
  blockquote {
    color: #555555;
    font-style: normal;
    margin-top: 1.5rem;
    blockquote {
      margin: 0.5rem 0;
    }
    p {
      margin: 0;
    }
  }

  /**
  * リスト
  */
  ol,
  ul {
    position: relative;
    margin-top: 1rem;
    padding: 0;
    list-style: none;
    ol,
    ul {
      padding-left: 0;
    }
    li {
      margin: 0.25em 0;
      // text-indent で先頭を揃える実装の代替案
      // text-indent で実装するとiphoneで先頭数文字が見えなくなる
      &::before {
        position: absolute;
        left: 0;
      }
      p {
        margin: 0;
      }
    }
  }

  // 入れ子の場合は先頭の余白は2em
  ol > li > ol > li {
    padding-left: 2em !important;
    &::before {
      width: 2em !important;
    }
  }

  ul > li {
    padding-left: 1em;
    &::before {
      content: '・';
      width: 1em;
    }
  }

  ol {
    counter-reset: list;
    li {
      counter-increment: list;
      &::before {
        display: inline-block;
        padding-right: 0.4rem;
        text-align: right;
      }
    }
  }

  ol > li {
    padding-left: 2.1em;
    &::before {
      content: counter(list) '.';
      width: 2.1em;
    }
  }

  ol.parentheses > li {
    padding-left: 2.4em;
    &::before {
      content: '(' counter(list) ')';
      width: 2.4em;
    }
  }

  ol.brackets > li {
    padding-left: 2.4em;
    &::before {
      content: '[' counter(list) ']';
      width: 2.4em;
    }
  }

  ol.roman > li {
    padding-left: 2.3em;
    &::before {
      content: counter(list, lower-roman) '.';
      width: 2.3em;
    }
  }

  ol.roman.parentheses > li {
    padding-left: 2.6em;
    &::before {
      content: '(' counter(list, lower-roman) ')';
      width: 2.6em;
    }
  }

  ol.roman.brackets > li {
    padding-left: 2.6em;
    &::before {
      content: '[' counter(list, lower-roman) ']';
      width: 2.6em;
    }
  }

  ul.loose,
  ol.loose {
    margin-bottom: -0.8rem;
    margin-top: calc(1.5rem - 0.8rem);
    border-spacing: 0 0.8rem;
  }

  /**
  * 画像
  */
  img {
    display: block;
    margin: 1.5rem auto 0;
    width: 85%;
    counter-increment: image;
  }

  .image-title {
    text-align: center;
    margin-top: 0.5rem;
    line-height: 1.25;
    &::before {
      content: '図' counter(image) '：';
      display: inline-block;
      vertical-align: text-top;
    }
    &:empty::before {
      content: '図' counter(image);
      display: inline-block;
      vertical-align: text-top;
    }
  }

  // 「displayMathを子要素に持つ要素」の隣接要素が br の場合 d-none
  .formula:has(> mjx-container[display='true']) + br {
    display: none;
  }

  /**
  * ボックス
  */
  div.box {
    margin-top: 1.5rem;
    border: 2px solid #262626;
    border-radius: 4px;
    .title {
      font-size: 1.1rem;
      font-weight: 600;
      padding: 0.75rem 0.75rem 0.5rem;
      width: 100%;
    }
    .body {
      padding: 0 0.75rem 0.75rem;
    }
    .body > :first-child,
    .body > :first-child > :first-child {
      margin-top: 0;
    }
    .body > :last-child,
    .body > :last-child > :last-child {
      margin-bottom: 0;
    }
  }

  div.normal {
    padding: 0.75rem;
  }

  div.conj .title {
    &::before {
      content: '予想';
      margin-right: 1em;
    }
  }

  div.axm .title {
    &::before {
      content: '公理';
      margin-right: 1em;
    }
  }

  div.def {
    counter-increment: def;
    .title::before {
      content: '定義' counter(def);
      margin-right: 1em;
    }
  }

  div.prop {
    counter-increment: thm-prop-lem;
    .title::before {
      content: '命題' counter(thm-prop-lem);
      margin-right: 1em;
    }
  }

  div.fml {
    counter-increment: fml;
    .title::before {
      content: '公式' counter(fml);
      margin-right: 1em;
    }
  }

  div.lem {
    counter-increment: thm-prop-lem;
    .title::before {
      content: '補題' counter(thm-prop-lem);
      margin-right: 1em;
    }
  }

  div.thm {
    counter-reset: cor;
    counter-increment: thm thm-prop-lem;
    .title::before {
      content: '定理' counter(thm-prop-lem);
      margin-right: 1em;
    }
  }

  div.cor {
    counter-increment: cor;
    .title {
      &::after {
        content: ' 系';
      }
      &:empty::after {
        content: '定理' counter(thm-prop-lem) ' 系' counter(cor);
      }
    }
  }

  div.prf .title::before {
    content: '証明';
    margin-right: 1em;
  }

  div.ex {
    counter-increment: ex;
    .title::before {
      content: '例' counter(ex);
      margin-right: 1em;
    }
  }

  div.exc {
    counter-increment: exc;
    .title::before {
      content: '問題' counter(exc);
      margin-right: 1em;
    }
  }

  div.rem .title::before {
    content: '注意';
    margin-right: 1em;
  }

  // テーブル（表）
  .table-container {
    overflow: auto;
    white-space: nowrap;
    margin: 1rem 0;
    table {
      border-collapse: collapse;
      tr:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05);
      }
      th,
      td {
        border: 1px solid #ccc;
        padding: 0.5rem;
        text-align: center;
      }

      th {
        font-weight: 500;
        background-color: #262626;
        color: #cccccc;
      }

      [align='right'] {
        text-align: right;
      }

      [align='left'] {
        text-align: left;
      }

      [align='center'] {
        text-align: center;
      }
    }
  }

  // 印刷時に途中で改行されることの防止
  @media print {
    .img-container,
    img,
    .box,
    .MathJax {
      break-inside: avoid !important;
      page-break-inside: avoid !important; // Firefox対応
    }
  }

  @include clear-initial-margin-top(4);
  @include clear-last-margin-bottom(4);
}

/**
  * 数式
  */
// inline数式はoverflow時のみスクロールバーを表示させる
.formula {
  position: relative;
}

.MathJax {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  // 特定のTexを書くと style 属性に min-width が指定されてスクロールバーが表示されなくなるので防止
  // 例）\tag
  min-width: auto !important;

  // 数式下のスクロールバーは細くして視認性を向上させる
  &::-webkit-scrollbar-thumb {
    border: 6px solid transparent !important;
  }

  // inline数式
  &:not([display='true']) {
    padding: 0 0.25em;
    vertical-align: middle;

    // inline数式はoverflow時のみスクロールバーを表示させる
    position: static !important; // style属性に入る position: relative; を上書き
    display: inline-block;
  }

  // 以下IOSモバイルで数式にノイズがのる問題に対処するために追加
  // AndroidのデバイスやMacBookでは起きない。
  // XYPicの場合はstroke: transparent;を指定すると動かないので指定しない
  // TODO: 本来はIOSのモバイルのみに適用して影響範囲を最小限するべき
  path {
    stroke: transparent;
  }
  &:has([data-mml-node='xypic']) {
    path {
      stroke: inherit;
    }
  }
}

/*
 * Mathjax の右クリック時のメニューを表示させる
 */
.CtxtMenu_MenuFrame {
  z-index: 900 !important;
}
