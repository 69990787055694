#__next,
html,
body {
  color: $primary-dark;

  // スクロールバー
  & ::-webkit-scrollbar {
    width: 16px;
  }
  & ::-webkit-scrollbar-thumb {
    background: #dadce0;
    background-clip: padding-box;
    border: 4px solid transparent;
    border-radius: 8px;
    box-shadow: none;
    min-height: 50px;
  }
  & ::-webkit-scrollbar-track {
    background: none;
    border: none;
  }

  font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Kaku Gothic ProN',
    'Hiragino Sans', Meiryo, sans-serif, 'Segoe UI Emoji';

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-weight: 700;
  }
}
