@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // font-size系をレスポンシブで調整可能に
    // bootstrapがimportant指定のため上書きするためにimportantが必要
    .fs#{$infix}-1 {
      font-size: calc(1.375rem + 1.5vw) !important;
    }
    .fs#{$infix}-2 {
      font-size: calc(1.325rem + 0.9vw) !important;
    }
    .fs#{$infix}-3 {
      font-size: calc(1.3rem + 0.6vw) !important;
    }
    .fs#{$infix}-4 {
      font-size: calc(1.275rem + 0.3vw) !important;
    }
    .fs#{$infix}-5 {
      font-size: 1.25rem !important;
    }
    .fs#{$infix}-6 {
      font-size: 1rem !important;
    }
    .fs#{$infix}-7 {
      font-size: 0.75rem !important;
    }
  }
}
