.btn {
  box-shadow: none !important;
}

.btn-link {
  box-shadow: none !important;
  text-decoration: none;
  padding: 0;
  line-height: 1;
}

// ヘッダーにおけるdropdown-toggleの不要な余白を削除
.nav-link.dropdown-toggle {
  padding: 0 !important;
}

// offcanvasのトグルのデザインを調整
.navbar-toggler {
  padding: 0.2rem 0.5rem !important;
  .navbar-toggler-icon {
    width: 1.4rem;
    height: 1.4rem;
  }
}

// ヘッダー内のDropdownメニューはヘッダー外に表示されるように調整
.navbar .dropdown-menu {
  margin-top: 0.8rem;
}

// navbarがdarkのときのdropdown iconの色を変更
.navbar-dark .dropdown-toggle::after {
  color: white !important;
}

@media (min-width: 1px) and (max-width: 960px) {
  a:hover {
    opacity: 1;
  }
}

// input をプレーンテキストにした場合 outline を非表示に変更
.form-control-plaintext {
  outline: 0 !important;
}

// bootstrap v5.3.0 だとデフォルト未指定のため定義されているz-indexを指定
.position-fixed {
  z-index: $zindex-fixed;
}
.position-sticky {
  z-index: $zindex-sticky;
}
.sticky-top {
  z-index: $zindex-sticky;
}
.zindex-fixed {
  z-index: $zindex-fixed;
}
.zindex-toast {
  z-index: $zindex-toast;
}

.text-muted {
  color: #6c757d !important;
}

.bg-primary.badge {
  background: $primary !important;
}

input.bg-dark::placeholder {
  color: #6c757d !important;
}

.dropdown .dropdown-item:active {
  background-color: var(--bs-tertiary-color);
}
