@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // gridのカラムをレスポンシブで調整可能に
    .g#{$infix}-cols-1 {
      --bs-columns: 1;
    }
    .g#{$infix}-cols-2 {
      --bs-columns: 2;
    }
  }
}
