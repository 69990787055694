@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // overflow系をレスポンシブで調整可能に
    .overflow#{$infix}-auto {
      overflow: auto !important; // bootstrapがimportant指定のため上書きするためにimportantが必要
    }
    .overflow#{$infix}-hidden {
      overflow: hidden !important; // bootstrapがimportant指定のため上書きするためにimportantが必要
    }
    .overflow#{$infix}-x-auto {
      overflow-x: auto !important; // bootstrapがimportant指定のため上書きするためにimportantが必要
    }
    .overflow#{$infix}-x-hidden {
      overflow-x: hidden !important; // bootstrapがimportant指定のため上書きするためにimportantが必要
    }
    .overflow#{$infix}-y-auto {
      overflow-y: auto !important; // bootstrapがimportant指定のため上書きするためにimportantが必要
    }
    .overflow#{$infix}-y-hidden {
      overflow-y: hidden !important; // bootstrapがimportant指定のため上書きするためにimportantが必要
    }
  }
}
