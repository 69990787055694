.lh-0 {
  line-height: 0 !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.scrollbar-none {
  -ms-overflow-style: none; // IE, Edge 対応
  scrollbar-width: none; // Firefox 対応
  &::-webkit-scrollbar {
    display: none; // Chrome, Safari 対応
  }
}

.dvh-100 {
  height: 100dvh !important;
}

.border-muted {
  border-color: #6c757d !important;
}

.dropdown-toggle-after-none:after {
  display: none;
}

.ratio-1200x630 {
  --bs-aspect-ratio: 52.5%; // 2x1
}
