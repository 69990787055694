@media print {
  * {
    -webkit-print-color-adjust: exact !important; // 印刷時に背景色が適用されないことの防止
  }

  // モノクロ設定での印刷時に要素をモノクロ化
  .monotone-printing {
    .badge {
      color: var(--bs-white) !important;
      background-color: var(--bs-gray-600) !important;
    }
    a,
    button {
      color: var(--bs-gray-900) !important;
    }
  }

  // 印刷時に要素を表示
  .d-print-block {
    display: block !important;
  }

  // 印刷時に要素を非表示
  .d-print-none {
    display: none !important;
  }
}
