$custom-primary: #3c5a82;
$primary-dark: #19202a;
$primary-light: #fffefe;

@import 'layout';
@import 'bootstrap';

// 印刷の設定
@import 'print';

// サイドナビの設定
@import 'sidenav';

// Mathdown
@import 'web_core/assets/mathdown/styles/colorful.scss';
@import 'web_core/assets/mathdown/styles/monotone.scss';
@import 'web_core/assets/mathdown/styles/editor.scss';
