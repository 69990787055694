@import './common';

.mathdown-monotone {
  @include common-modules;

  p {
    text-indent: 1em;
    :not(p) {
      text-indent: 0;
    }
  }

  blockquote {
    border-left: 5px solid #cccccc;
    padding-left: 0.5rem;
    blockquote {
      margin: 0.5rem 0 0.5rem 0.25rem;
    }
  }

  @media print {
    #{H4}::before {
      background-color: var(--bs-gray-600);
    }
  }
}
