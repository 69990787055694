.bg-transparent {
  background-color: transparent !important;
}

.primary-dark-shadow {
  box-shadow: 10px 10px 0px 0px $primary-dark;
}

.bg-primary-dark {
  background-color: $primary-dark !important;
}

.bg-primary-light {
  background-color: $primary-light !important;
}

.bg-light-gray {
  background-color: #f4f6f8;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .bg#{$infix}-primary-dark {
      background-color: $primary-dark !important;
    }
    .bg#{$infix}-primary-light {
      background-color: $primary-light !important;
    }
  }
}
