// SVGをを指定した大きさで表示するためのラッパー
// .svg-wrapperの親要素に横幅と縦幅を指定する
.svg-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
