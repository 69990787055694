// Configuration
@import '~bootstrap/scss/functions';

// default color を変更
$primary: $custom-primary;

// ネガティブマージンを有効可
$enable-negative-margins: true;

$enable-dark-mode: false;

// bootstrap の breadcrumb のデフォルトの余白を無効化
$breadcrumb-margin-bottom: 0;

@import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

// cssgridを有効化
$enable-grid-classes: true; // 既存のgrid（row, col）も無効にしない
$enable-cssgrid: true;

// 色を追加
$custom-utilities-text-colors: (
  'pink': $pink-400,
  'orange': $orange,
);
$utilities-text-colors: map-merge(
  $utilities-text-colors,
  $custom-utilities-text-colors
);

// 字の大きさを追加
$custom-font-sizes: (
  7: 0.75rem,
);
$font-sizes: map-merge($font-sizes, $custom-font-sizes);

// スペース幅を追加・変更
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer * 0.75,
  4: $spacer,
  5: $spacer * 1.25,
  6: $spacer * 1.5,
  7: $spacer * 3,
);

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);

$link-decoration: none;
